import React, { Component } from 'react';
import close from '../img/close.png'

export default class Overlay extends Component {

  constructor(props) {
    // Required to call original constructor
    super(props);
    console.log("Overlay data", this.props)
    this.state = {brochure: this.props.data.body[1]}
    this.close = this.close.bind(this);
}
  
  close(){
    this.props.hideBrochure()
  }

  render() {
    console.log(this.state);
    var brochures = this.props.data.body[1].items.map(item =>
      <div className="download"><span><a href={item.file.url}>{item.text[0].text}</a></span></div>
  )
    //var video = data.video.url
    //return <div></div>
    return (
      <div className={this.props.show == true?"":"hide"} id="overlayBrochure">
     
            <div id="brochureContainer">
            
                <img className="brochureImage" src={this.props.data.body[1].primary.image.url}/>
                <div className="downloadContainer">
                <img onClick={this.close} className="closeBrochure" src={close}/>
                    <h3>{this.props.data.body[1].primary.title[0].text}</h3>
                    <div className="text">{this.props.data.body[1].primary.text[0].text}</div>
                    {brochures}
                </div>
            </div>
        </div>
    );
  }
}