import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Typekit from 'react-typekit';
import LanguageSelect from './languageSelect.js'
import Overlay from './Overlay.js'
import { Link } from "gatsby"
import logo from '../img/logo_martal.svg'
import forte from '../img/forte_logo.svg'
import menu from '../img/menuIcon.png'
import close from '../img/close.png'
import { CSSTransition, transit } from "react-css-transition";
import {Helmet} from "react-helmet";
import {fetchNav} from "../actions/navigationActions.js"
import logofsc from '../img/icons/logo-fsc.png'
import dropdownicon from '../img/icons/icon-dropdown.svg'
import gafas from '../img/gafas-logo.png'
import arrow from '../img/icons/icon-arrow-right-red.svg'

import "../styles/global.scss"
import "../styles/carousel.scss"
import "../styles/Footer.scss"
import "../styles/Header.scss"
import "../styles/Home.scss"
import "../styles/Products.scss"
import "../styles/product-single.scss"
import "../styles/select.scss"
import "../styles/video.scss"
import "../styles/about.scss"
import "../styles/contact.scss"
import "../styles/brochure.scss"

var languages = {
  'nl': 0,
  'en': 1,
  'fr': 2,
  '': 0,
  '/':0
};

var languagesPrismic = {
  0 : 'nl-be',
  1 : 'en-gb',
  2 : 'fr-be'
};

const productenslug = {
  "nl-be": "producten",
  "en-gb": "products",
  "fr-be": "produits"
}

class DefaultLayout extends React.Component {
  constructor(props) {
    // Required to call original constructor
    super(props);
    // Props are now accessible from here
    var v = props.activePage;
    var data = props.menu
    console.log("DefaultLayout", this);
    /*var path = this.props.location.pathname.indexOf("/")
    var slug = this.props.location.pathname.substr(path+1,5)
    var selectlanguage = languages[this.props.location.pathname.substr(1,2)]
    if (slug == '/nl-be' || slug=="" || slug=="/"){
      selectlanguage = 0
    } else if(slug == '/en-gb'){
      selectlanguage = 1
    }else if(slug == '/fr-be'){
      selectlanguage = 2
    }

    var nl_be;
    var en_gb;
    var fr_be;

    for(var i = 0;i<props.data.allPrismicDocument.edges.length;i++){
      if(props.data.allPrismicDocument.edges[i].node.lang=="nl-be"){
        nl_be = this.props.data.allPrismicDocument.edges[i].node.data
      }
      if(props.data.allPrismicDocument.edges[i].node.lang=="en-gb"){
        en_gb = this.props.data.allPrismicDocument.edges[i].node.data
      }
      if(props.data.allPrismicDocument.edges[i].node.lang=="fr-be"){
        fr_be = this.props.data.allPrismicDocument.edges[i].node.data
      }
    }

    var brochure = this.props.data.aboutpage;
    console.log("brochure", brochure)
    var brochureNL;
    var brochureEN;
    var brochureFR;

    for(var i = 0;i<props.data.aboutpage.edges.length;i++){
      if(props.data.aboutpage.edges[i].node.lang=="nl-be"){
        brochureNL = this.props.data.aboutpage.edges[i].node.data
      }
      if(props.data.aboutpage.edges[i].node.lang=="en-gb"){
        brochureEN = this.props.data.aboutpage.edges[i].node.data
      }
      if(props.data.aboutpage.edges[i].node.lang=="fr-be"){
        brochureFR = this.props.data.aboutpage.edges[i].node.data
      }
    }

    var lang = slug;
*/
    this.state = {
      showBrochure: false,
      selectedOption: "",
      active: false,
      language: "",
      data: this.props.data,
      dataBrochure: "",
      productOpen: false
    }

    this.handleClick = this.handleClick.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.toggleBrochures = this.toggleBrochures.bind(this);
    this.handleProductClick = this.handleProductClick.bind(this);
    this.handleOutsideClick  = this.handleOutsideClick.bind(this);
    this.handleProductmouseOver = this.handleProductmouseOver.bind(this)
}

componentDidMount(){
   window.addEventListener('scroll', () => {
     if(this.state.productOpen){
       this.setState({...this.state, productOpen: false});
     }
   });

   /*document.getElementById("collapseMenu").addEventListener('mouseleave', (e) => {
     if(this.state.productOpen){
       this.setState({...this.state, productOpen: false});
     }
   });*/
}

componentWillUnmount(){
  //window.removeEventListener("scroll");
  //document.getElementById("collapseMenu").removeEventListener("scroll");
}

handleClick() {
  this.setState({active: !this.state.active});
}

handleProductClick(){
  this.setState({
    ...this.state,
    productOpen: !this.state.productOpen
  })
  document.addEventListener('click', this.handleOutsideClick, false);
}

handleProductmouseOver(e){
  console.log(e)
  this.setState({
    ...this.state,
    productOpen: true
  })
  document.addEventListener('click', this.handleOutsideClick, false);
}

componentWillUpdate(){
  console.log("teeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeest componentwillupdate")
}

componentWillMount(){
  //console.log('location', languagesPrismic[languages[this.props.location.pathname.substr(1,2)]])

  //this.props.dispatch(fetchNav(languagesPrismic[languages[this.props.location.pathname.substr(1,2)]]))
}

changeLanguage(language){
//this.props.dispatch(fetchNav(languagesPrismic[language]))
}

toggleBrochures(){
  this.setState({
    ...this.state,
    showBrochure: !this.state.showBrochure
  })
}

handleOutsideClick(e) {
  // ignore clicks on the component itself
  console.log(e);

  this.setState({...this.state, productOpen: false});
  document.removeEventListener('click', this.handleOutsideClick, false);
}

onSelectChange(e){

  //this.props.dispatch(fetchNav(languagesPrismic[e.target.value]))
  //this.props.history.push('/');
  console.log("mainfuncion", e)



  //this.props.history.push(languagesPrismic[e]);
  //this.setState({...this.state,selectedOption:e, language:slug});
  // var path = this.props.location.pathname.indexOf("/")
  //var slug = this.props.location.pathname.substr(path+1,5)
  //console.log(e);

}

  render() {
    
    console.log('props', this.props)
    console.log("state nav", this.state)
    //console.log("state nav", languagesPrismic[languages[this.props.location.pathname.substr(1,2)]])
    const {data, navFetched} = this.props;
    var mappeditems = {};
    var mappedMobileItems = {};
    var languageLink = "/"
    console.log(languageLink);


    var lang = languageLink;
    var filteredArray, orderderedarray = [];
    /*var filteredArray = {'hardhout': [], 'massievePanelen': []};
    for(var i = 0;i<this.props.data.producten.edges.length;i++){
        if(this.props.data.producten.edges[i].node.lang==lang){
            if(this.props.data.producten.edges[i].node.data.type_wood == 'hardhout')
            {
                filteredArray.hardhout.push(this.props.data.producten.edges[i].node)
            }else if (this.props.data.producten.edges[i].node.data.type_wood == 'paneel'){
                filteredArray.massievePanelen.push(this.props.data.producten.edges[i].node)
            }
        }
    }*/

    //order array
    /*var orderderedarray = {'hardhout': filteredArray.hardhout.slice(), 'massievePanelen': filteredArray.massievePanelen.slice()};
    for(var i = 0;i<filteredArray['hardhout'].length;i++){
        if(filteredArray['hardhout'][i].data.order>0 && filteredArray['hardhout'][i].data.order != null){

            var target = filteredArray['hardhout'][i].data.order-1;
           /* var b = filteredArray['hardhout'][i];
            console.log("target", target + " / now " + (i))
            filteredArray['hardhout'][i] = filteredArray['hardhout'][target];
            filteredArray['hardhout'][target] = b;
            orderderedarray.hardhout[target] = filteredArray['hardhout'][i];
        }
    }

    for(var i = 0;i<filteredArray['massievePanelen'].length;i++){
        if(filteredArray['massievePanelen'][i].data.order>0 && filteredArray['massievePanelen'][i].data.order != null){
            var target = filteredArray['massievePanelen'][i].data.order-1;
            /*var b = filteredArray['massievePanelen'][i];
            filteredArray['massievePanelen'][i] = filteredArray['massievePanelen'][filteredArray['massievePanelen'][i].data.order-1];
            filteredArray['massievePanelen'][order] = b;
            orderderedarray.massievePanelen[target] = filteredArray['massievePanelen'][i];
        }
    }*/

    console.log("filteredArray", filteredArray)

    var mappeditems, mappedMobileItems = [];
    console.log("menu", data);
    /*mappeditems = this.state.data[languages[this.props.location.pathname.substr(1,2)]].body[0].items.map((item, i) =>
      <li onMouseEnter={i==0?this.handleProductmouseOver:""} key={item.link_id[0].text} className="menuItem"><Link activeStyle={{color: '#E21D19'}} to={"/"+languageLink+"/"+item.link_id[0].text} className={(this.props.activePage === '/') ? 'active' :''}>{item.link_name[0].text}</Link><div onClick={this.handleProductClick} className={i==0?"productsDropdow":"hide"}><img className={this.state.productOpen?"dropdownRotate":""} src={dropdownicon} /></div></li>

    )*/

    /*mappedMobileItems = this.state.data[languages[this.props.location.pathname.substr(1,2)]].body[0].items.map(item =>
      <Link key={item.link_name[0].text} activeStyle={{color: '#E21D19'}} to={languageLink+"/"+item.link_id[0].text} className={(this.props.activePage === '/'+item.link_name[0].text) ? 'active mobileMenuItem' :'mobileMenuItem'}>{item.link_name[0].text}<div key={item.link_name[0].text} className=""></div></Link>
    )*/

    /*mappedMobileItems = this.state.data[languages[this.props.location.pathname.substr(1,2)]].body[0].items.map(item =>
      <li>    <Link key={item.link_name[0].text} to={"/"+languageLink+"/"+item.link_id[0].text} className={(this.props.activePage === '/'+item.link_name[0].text) ? 'active mobileMenuItem' :'mobileMenuItem'}>{item.link_name[0].text}<div key={item.link_name[0].text} className=""></div></Link>
      </li>
    )*/

    var mappedFooter = [];
    /*this.state.data[languages[this.props.location.pathname.substr(1,2)]].body[0].items.map(item =>
      <li key={item.link_name[0].text}><Link to={"/"+languageLink+"/"+item.link_id[0].text}>{item.link_name[0].text}</Link></li>
    )*/

    var mappedHardhout = [];
    /*orderderedarray.hardhout.map(item=>
      <li><Link activeStyle={{color: '#E21D19'}} to={"/"+languageLink+"/"+productenslug[languageLink]+"/"+item.slugs[0]}>{item.data.title[0].text}</Link></li>
    )*/

    var mappedPanelen = [];
    /*orderderedarray.massievePanelen.map(item=>
      <li><Link activeStyle={{color: '#E21D19'}} to={"/"+languageLink+"/"+productenslug[languageLink]+"/"+item.slugs[0]}>{item.data.title[0].text}</Link></li>
    )*/

    var mappedSpecialiteiten = [];
    mappedSpecialiteiten = data.body[0].items.map(item=>
      <li><Link activeStyle={{color: '#E21D19'}} to={"/specialisaties"}>{item.titel.text}</Link></li>
    )

    console.log(this.props.children);

    return (
        <div>

        <Typekit kitId="foj5fcp" />
        <Helmet title="test">
              <title>{"test"}</title>
              <meta name="description" content={"test2"} />
            </Helmet>
        <header>
          <div id="menuLeft">
            <div id="logoContainer">
            <Link to={"/"}><img className="logo" src={forte} alt="Martal logo" /></Link>
            </div>
            <div className="rectangle"></div>
            <div id="menuContainer">
              <ul id="menu" className="tk-acumin-pro">
              <li onMouseEnter={this.handleProductmouseOver} key={data.diensten.text} className="menuItem"><Link activeStyle={{color: '#be2f69'}} to={"/specialisaties"} className={(this.props.activePage === '/specialisaties') ? 'active' :''}>{data.diensten.text}</Link><div onClick={this.handleProductClick} className="productsDropdow"><img className={this.state.productOpen?"dropdownRotate":""} src={dropdownicon} /></div></li>
              <li key={data.over_ons.text} className="menuItem"><Link activeStyle={{color: '#be2f69'}} to={"/over-ons"} className={(this.props.activePage === '/over-ons') ? 'active' :''}>{data.over_ons.text}</Link></li>
              <li key={data.jobs.text} className="menuItem"><Link activeStyle={{color: '#be2f69'}} to={"/over-ons#jobs"} className={(this.props.activePage === '/over-ons') ? 'active' :''}>{data.jobs.text}</Link></li>
              <li key={data.contact.text} className="menuItem"><Link activeStyle={{color: '#be2f69'}} to={"/contact"} className={(this.props.activePage === '/contact') ? 'active' :''}>{data.contact.text}</Link></li>
              </ul>
            </div>
          </div>
          <div id="menuRight">
          <div>

          </div>
            <div id="number">
            03 / 707 04 00
            </div>
            <div className="rectangle"></div>
             <a className={this.state.active ? "on" : ""} href="#" id="toggle" onClick={this.handleClick}><span></span></a>
            <div id="brochuresHeader">
             <a href="/contact">Maak een afspraak</a>
           </div>
          </div>
          <div className="clear"></div>
          <div onClick={this.handleClick} className={this.state.active ? 'mobileMenu' : 'mobileMenu hide'}>
          
        
          </div>

          <div onClick={this.handleClick} className={this.state.active ? 'a-mobilenav nav-active' : 'a-mobilenav'}>
          <div className="closeMenu"></div>
            <nav>
              <ul>
                <li><Link key={data.diensten.text} to={"/specialisaties"} className={(this.props.activePage === '/'+data.diensten.text) ? 'active mobileMenuItem' :'mobileMenuItem'}>{data.diensten.text}<div key={data.diensten.text} className=""></div></Link> </li>
                <li><Link key={data.over_ons.text} to={"/over-ons"} className={(this.props.activePage === '/'+data.over_ons.text) ? 'active mobileMenuItem' :'mobileMenuItem'}>{data.over_ons.text}<div key={data.over_ons.text} className=""></div></Link> </li>
                <li><Link key={data.jobs.text} to={"/over-ons#jobs"} className={(this.props.activePage === '/'+data.over_ons.text) ? 'active mobileMenuItem' :'mobileMenuItem'}>{data.jobs.text}<div key={data.jobs.text} className=""></div></Link> </li>
                <li><Link key={data.contact.text} to={"/contact"} className={(this.props.activePage === '/'+data.contact.text) ? 'active mobileMenuItem' :'mobileMenuItem'}>{data.contact.text}<div key={data.contact.text} className=""></div></Link> </li>
              </ul>
            </nav>
          </div>



          <div className="menu right">
            <div className="menu-item">First Page</div>
            <div className="menu-item">Second Page</div>
            <div className="menu-item">Third Page</div>
         </div>

        </header>
        <div className={this.state.productOpen==true?"global-sub-menu global-sub-menu-active":"global-sub-menu"}>
            <div id="collapseMenu" className="global-sub-menu__inner flex flex-wrap u-justify--between">
              <div className="global-sub-menu__block">
                <ul className="flex flex-wrap">
                {mappedSpecialiteiten}
                </ul>
              </div>
            </div>
          </div>

 <div className={this.state.active || this.state.showBrochure ?"test123 homepagePreventScrolling":"test123"} id="body">
        <div>{this.props.children}</div>
        
        
        </div>
        
        </div>
      );
    }

}

export default DefaultLayout;