import React from "react"
import PropTypes from "prop-types"
import { navigateTo } from "gatsby-link"
import dropdownicon from '../img/icons/icon-dropdown.svg'
import Link from "gatsby-link"

var languages = {
  'nl': 0,
  'en': 1,
  'fr': 2
};

var languagesPrismic = {
  0 : 'nl-be',
  1 : 'en-gb',
  2 : 'fr-be'
};

var languagesCodes = {
    "nl-be" : "NL",
    "en-gb" : "EN",
    "fr-be" : "FR"
}
var links

var languagesArray = ["nl-be", "en-gb", "fr-be"];

class languageSelect extends React.Component {
  constructor(props) {
    // Required to call original constructor
    super(props);
    // Props are now accessible from here
    var v = props.activePage;
    var data = props.menu
    console.log(this);
    var path = ""
    var slug = ""
    var selectlanguage = languages['nl']
    if (slug == '/nl-be' || slug=="" || slug=="/"){
      selectlanguage = 0
    } else if(slug == '/en-gb'){
      selectlanguage = 1
    }else if(slug == '/fr-be'){
      selectlanguage = 2
    }

    this.state = {
      selectedOption: selectlanguage,
      active: false,
      language: slug,
    }
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick  = this.handleOutsideClick.bind(this);
    this.onSelectChangeParent = this.onSelectChangeParent.bind(this);
}

handleClick() {
  //this.setState({active: !this.state.active});
    console.log("click")
    this.setState({...this.state, active: true});
    document.addEventListener('click', this.handleOutsideClick, false);
}

componentWillMount(){
 // console.log('location', languagesPrismic[languages[this.props.location.pathname.substr(1,2)]])

  //this.props.dispatch(fetchNav(languagesPrismic[languages[this.props.location.pathname.substr(1,2)]]))
}

changeLanguage(language){
//this.props.dispatch(fetchNav(languagesPrismic[language]))
}

onSelectChangeParent(e){
    this.props.onChangeFunction(e.target.className);
}

handleOutsideClick(e) {
    // ignore clicks on the component itself
    console.log(e);

    this.setState({...this.state, active: false});
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  render() {
    console.log('props', this.props)
    console.log("state nav", this.state)
    const {data, navFetched} = this.props;
    var mappeditems = {};
    var mappedMobileItems = {};
    var languageLink = "nl-be"
    //console.log(this.props.location.pathname.substr(1,2))

   /* if(this.props.location.pathname.substr(1,2)==''){
      languageLink = 'nl-be';
    }*/


    //laguages list select
    var languageItems = [];

    for(var i = 0;i<languagesArray.length;i++){
        if(languagesArray[i] != this.props.lang){
            languageItems.push(languagesArray[i]);
        }
    }

    console.log(languageItems)

    const linksdata = this.props.linksData;
    const link = this.props.link;
    console.log("link", link);
    let type;
    //get page type
    for(var i = 0;i<linksdata.length;i++){

      if(link.indexOf(linksdata[i].node.slugs[0])!=-1 && linksdata[i].node.slugs[0] != "nl-be"
          && linksdata[i].node.slugs[0] != "fr-be"
          && linksdata[i].node.slugs[0] != "en-gb"){
            console.log(linksdata[i].node.slugs[0])
            type = linksdata[i].node.type;
            i = linksdata.length
      }else{
        type="homepage"
      }
    }

    console.log(type);

    //select right slugs
    var arraySlugs = []
    for(var i = 0;i<linksdata.length;i++){
      if(type==linksdata[i].node.type){
        arraySlugs.push(linksdata[i])
      }
    }

    //select correct language-slug
    console.log(arraySlugs);
    let slugsLanguage = {};
    for(var i = 0;i<languageItems.length;i++){
      for(var j = 0;j<arraySlugs.length;j++){
        if(arraySlugs[j].node.lang == languageItems[i])
        slugsLanguage[languageItems[i]] = arraySlugs[j].node.slugs[0];
        if(type=="homepage"){
          slugsLanguage[languageItems[i]] = "";
        }
      }
    }
    console.log(slugsLanguage)
    //create links
    var mappeditems;

    mappeditems = languageItems.map(item=>
        <li className={item}><Link to={"/"+item+"/"+slugsLanguage[item]}>{languagesCodes[item]}</Link></li>
    )

    return (
            <div id="Language">
              <div onClick={this.handleClick} className="btnLanguage">
                <div className="lang-text">{languagesCodes[this.props.lang]}</div>
                <div className="lang-icon">
                  <img className={this.state.active == true?"dropdownRotate":""} src={dropdownicon} />
                </div>
              </div>

              <div className={this.state.active == true?"lang-switcher opacityShow":"lang-switcher"}>
                  <ul>
                    {mappeditems}
                  </ul>
              </div>
            </div>

      );
    }

}

export default languageSelect;
